import { createSlice } from '@reduxjs/toolkit';
import { ResumeSections } from 'types/resumeTypes';

const initialState: ResumeSections = {
  loading: false,
  loaded: false,
  experiences: [],
  techs: [],
  schools: [],
  certs: [],
};

// REDUCERS
const ResumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setExperiences: (state, action) => {
      state.experiences = action.payload;
    },
    setTechs: (state, action) => {
      state.techs = action.payload;
    },
    setSchools: (state, action) => {
      state.schools = action.payload;
    },
    setCerts: (state, action) => {
      state.certs = action.payload;
    },
  },
});

// ACTION CREATORS
export const { setLoading, setLoaded, setExperiences, setTechs, setSchools, setCerts } =
  ResumeSlice.actions;

export default ResumeSlice.reducer;
