import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from './en/translations';
// import { TRANSLATIONS_FR } from './fr/translations';
// import { TRANSLATIONS_SP } from './sp/translations';

// https://www.i18next.com/overview/configuration-options
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      /*
            fr: {
                translation: TRANSLATIONS_FR,
            },
            sp: {
                translation: TRANSLATIONS_SP,
            },
             */
    },
    fallbackLng: 'en',
    // debug: true, // debug messages in the console. (remove in production if using).

    interpolation: {
      escapeValue: false, // react already escapes all strings; safe from xss
    },
  });

i18n.changeLanguage('en');

export default i18n;
