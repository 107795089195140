// skeleton editor: https://skeletonreact.com/

import ContentLoader from 'react-content-loader';

export function PortfolioLoader() {
  return (
    <li>
      <ContentLoader
        speed={2}
        width='100%'
        height='100%'
        viewBox='0 0 435 400'
        backgroundOpacity={1}
      >
        <rect x='0' y='0' rx='3' ry='3' width='430' height='242' />
        <rect x='47' y='258' rx='3' ry='3' width='338' height='20' />
        <rect x='0' y='290' rx='0' ry='0' width='430' height='17' />
      </ContentLoader>
    </li>
  );
}

export function ResumeLoader() {
  return (
    <li>
      <ContentLoader
        speed={2}
        width='100%'
        height='100%'
        viewBox='0 0 400 120'
        backgroundOpacity={1}
      >
        <rect x='100' y='5' rx='5' ry='5' width='200' height='16' />
        <rect x='125' y='29' rx='5' ry='5' width='150' height='14' />
        <rect x='125' y='50' rx='5' ry='5' width='150' height='10' />
        <circle cx='25' cy='78' r='8' />
        <rect x='40' y='73' rx='5' ry='5' width='340' height='10' />
        <circle cx='25' cy='105' r='8' />
        <rect x='40' y='100' rx='5' ry='5' width='340' height='10' />
      </ContentLoader>
    </li>
  );
}

export function DefaultLoader() {
  return (
    <li>
      <ContentLoader
        speed={2}
        width='100%'
        height='100%'
        viewBox='0 0 476 124'
        backgroundOpacity={1}
      >
        <rect x='0' y='1' rx='3' ry='3' width='228' height='6' />
        <rect x='0' y='17' rx='3' ry='3' width='228' height='6' />
        <rect x='0' y='33' rx='3' ry='3' width='228' height='6' />
      </ContentLoader>
    </li>
  );
}
