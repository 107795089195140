import { Provider } from 'react-redux';
import store from './store';

/**
 * Reusable State Provider - can be used in App and also in tests
 * @param children - child components
 * @param store - redux store
 */
type Props = {
  children: JSX.Element;
};

function ProviderWrapper({ children }: Props) {
  return <Provider store={store}>{children}</Provider>;
}

export default ProviderWrapper;
