import { useRef, useState } from 'react';

// router
import { useLocation } from 'react-router-dom';

// internationalization
import { useTranslation } from 'react-i18next';

// components
import Navbar from 'components/organisms/Navbar/Navbar';

// hooks
import useScrollPosition, { ScrollPosHistoryType } from 'hooks/useScrollPosition';

// theming
import styles from './Header.module.scss';
import Mascot from '../../molecules/Mascot/Mascot';

function Header() {
  const location = useLocation();
  const { t } = useTranslation();

  const [isHeaderShrunk, setIsHeaderShrunk] = useState<boolean>();
  const headerShrunk = useRef(false);

  const setHeaderShrunkState = (isShrunk: boolean) => {
    headerShrunk.current = isShrunk || false;
    setIsHeaderShrunk(isShrunk);
  };

  useScrollPosition(({ curPos }: ScrollPosHistoryType) => {
    if (curPos) {
      if (curPos.y < 0 && !headerShrunk.current) {
        setHeaderShrunkState(true);
      } else if (curPos.y >= 0 && headerShrunk.current) {
        setHeaderShrunkState(false);
      }
    }
  });

  return (
    <header className={`${styles.Header} ${isHeaderShrunk ? styles.shrink : ''}`}>
      <div className={styles.headerWrap}>
        <Mascot />
        <h1>{t('header.title')}</h1>
      </div>

      <Navbar curRoute={location.pathname} />
    </header>
  );
}

export default Header;
