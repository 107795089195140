// todo - served from flat-file for now. set up a backend/api to serve the data
// import { apiPortfolioAxios } from 'utils/axiosInterceptors';
import { serveFlatFile } from 'utils/flatFileServer';

import portfolioData from 'store/data/portfolio.json';

/**
 * Demo Sagas / Redux
 * getPortfolioRedux called by Saga on FETCH__PORTFOLIO
 */
export const getPortfolioRedux = async () => {
  // TODO - demo REST call when set up backend
  // https://jsonplaceholder.typicode.com/
  // https://jsonplaceholder.typicode.com/todos
  // const response = await apiPortfolioAxios.get('/todos');

  return new Promise((resolve) => {
    resolve(portfolioData);
  });
};

/**
 * Simulate suspense/async on flatfile JSON
 * todo - when real backend is hooked up, wire this up to the saga flow
 */
const getSuspendedCards = () => {
  return serveFlatFile(portfolioData);
};

export default getSuspendedCards;
