// router
import Routes from 'router/routes';

// internationalization
import { useTranslation } from 'react-i18next';

// components
import { Link } from 'react-router-dom';

// theme
import styles from './404.module.scss';

function PageNotFound404() {
  const { t } = useTranslation();

  const GuideUserToSafety = Routes.map((route, index) => {
    const { id, path, page } = route;

    const punctuate = () => {
      let punctuation = ', ';

      if (index === Routes.length - 2) {
        punctuation = ` ${t('404.or')} `;
      } else if (index === Routes.length - 1) {
        punctuation = ` ${t('404.instead')}?`;
      }

      return punctuation;
    };

    return (
      <span key={id}>
        <Link to={path}>{t(`404.pages.${page.toLowerCase()}`)}</Link>
        {punctuate()}
      </span>
    );
  });

  return (
    <div className={styles.pageNotFound}>
      <div className={styles.head}>
        <h2>{t('404.header')}</h2>
        <h3>{t('404.subhead')}</h3>
        <h3>{t('404.subhead2')}</h3>
      </div>
      {`${t('404.cta')}`} {GuideUserToSafety}
    </div>
  );
}

export default PageNotFound404;
