import { call, put, takeLatest } from 'redux-saga/effects';

// api calls
import { getPortfolioRedux } from 'api/portfolioApi';

// redux
import { setLoading, setLoaded, setCards, setEmployers } from '../reducers/portfolioSlice';
import { portfolioActions as actions } from '../actions/portfolioActions';

export function* fetchPortfolio() {
  try {
    yield put(setLoading(true));

    // @ts-ignore todo - add generator types
    const result = yield call(getPortfolioRedux);

    if (result && result.length && result[0].projects && result[0].past) {
      const { projects, past } = result[0];

      // set portfolio cards
      yield put(setCards(projects));

      // set past employers
      yield put(setEmployers(past));

      yield put(setLoaded(true));
      yield put(setLoading(false));
    } else {
      throw new Error('portfolio api failed to load data');
    }
  } catch (e) {
    /* eslint-disable no-console */
    // console.error(e);
    yield put(setLoading(false));
  }
}

function* portfolioSaga() {
  yield takeLatest(actions.PORTFOLIO__FETCH, fetchPortfolio);
}

// eslint-disable-next-line import/prefer-default-export
export const sagas = [portfolioSaga()];
