import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';

// components
import Header from 'components/organisms/Header/Header';
import Main from 'components/organisms/Main/Main';
import Footer from 'components/organisms/Footer/Footer';
import ErrorBoundaryFallback from 'components/organisms/ErrorBoundary/ErrorBoundaryFallback';

// fonts
import rockSalt from '@fontsource/rock-salt';
import titilliumWeb from '@fontsource/titillium-web';

// configs
import SEO from 'constants/seo';

// theme
import styles from './App.module.scss';

function App() {
  const [hasError, setHasError] = useState(false);

  const {
    charSet,
    themeColor,
    viewport,
    robots,
    imgHt,
    imgWd,
    type,
    siteDesc,
    siteUrl,
    siteName,
    twitterHandle,
    twitterCardType,
  } = SEO;

  const logToErrorLoggingService = (error: Error, componentStack: { componentStack: string }) => {
    /* eslint-disable no-console */
    console.error('Loose wires. Log to service if needed', error, componentStack);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet={charSet} />
        <meta name='theme-color' content={themeColor} />
        <meta name='viewport' content={viewport} />
        <meta name='description' content={siteDesc} />
        <meta name='robots' content={robots} />
        <meta property='og:site_name' content={siteName} />
        <meta property='og:url' content={siteUrl} />
        <meta property='og:type' content={type} />
        <meta property='og:image:width' content={imgWd} />
        <meta property='og:image:height' content={imgHt} />
        <meta property='twitter:card' content={twitterCardType} />
        <meta property='twitter:site' content={twitterHandle} />
        <link href={rockSalt} rel='prefetch' as='font' type='font/woff2' crossOrigin='anonymous' />
        <link
          href={titilliumWeb}
          rel='prefetch'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <title>{siteName}</title>
      </Helmet>

      <div className={styles.ContainerFluid}>
        <Header />
        <div className={styles.ContentWrapper}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onReset={() => setHasError(false)}
            onError={(error, componentStack) => {
              logToErrorLoggingService(error, componentStack);
            }}
            resetKeys={[hasError]}
          >
            <Main />
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
