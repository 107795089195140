import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// initialize Redux, provide to app components
import ReduxProvider from 'Provider';

// initialize React Router
import { BrowserRouter as ReactRouterDom } from 'react-router-dom';

// initialize Helmet for SEO/dynamic meta tagging in the head
import { HelmetProvider } from 'react-helmet-async';

// initialize i18/internationalization
import './translations/i18n';

// components
import App from 'components/App';
import ScrollToTop from 'router/ScrollToTop';
import reportWebVitals from './reportWebVitals';

// demonstrate css variables as well as SASS
import './theme/global.css';

if (process.env.NODE_ENV !== 'production') {
  // react axe to check accessibility/WCAG
  /* eslint-disable global-require */
  /* eslint-disable import/no-extraneous-dependencies */
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000); // let it render a second first
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ReduxProvider>
      <HelmetProvider>
        <ReactRouterDom>
          <ScrollToTop />
          <App />
        </ReactRouterDom>
      </HelmetProvider>
    </ReduxProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
