const APP = {
  routes: {
    portfolio: 'portfolio',
    resume: 'resume',
    contact: 'contact',
  },
  contact: {
    email: 'email',
    linkedIn: 'linkedin',
    gitHub: 'github',
  },
};

export default APP;
