import { combineReducers } from '@reduxjs/toolkit';

// collect app reducer slices
import resumeReducer from './reducers/resumeSlice';
import portfolioReducer from './reducers/portfolioSlice';

// reduce all slices to a single root reducer object
const rootReducer = combineReducers({
  resume: resumeReducer,
  portfolio: portfolioReducer,
});

export default rootReducer;
