// internationalization
import { useTranslation } from 'react-i18next';

// theme
import styles from './Footer.module.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={styles.Footer}>
      <p className='small'>{t('footer.desc')}</p>
    </footer>
  );
}

export default Footer;
