// todo - served from flat-file for now. set up a backend/api to serve the data
// import { apiResumeAxios } from 'utils/axiosInterceptors';
import { serveFlatFile } from 'utils/flatFileServer';

import resumeData from 'store/data/resume.json';

/**
 * Demo Sagas / Redux
 * getResumeRedux called by Saga on FETCH__RESUME
 */
export const getResumeRedux = async () => {
  return new Promise((resolve) => {
    resolve(resumeData);
  });
};

/**
 * Simulate suspense/async on flatfile JSON
 * todo - when real backend is hooked up, wire this up to the saga flow
 */
const getSuspendedResume = () => {
  return serveFlatFile(resumeData);
};

export default getSuspendedResume;

/**
 * TODO demo GRAPHQL when backend available
 */
/*
const getResume = async (name: string) => {
  // https://countries.trevorblades.com/
  const url: string | undefined = process.env.REACT_APP_API_RESUME_URL;
  let data;

  if (url) {
    const query = `{
          country(code: "${name}") {
              name
              native
              capital
              emoji
              currency
              languages {
                  code
                  name
              }
          }
      }`;

    const response = await apiResumeAxios.post(url, { query });
    data = response?.data?.data?.country;
  }

  return data;
};
*/
