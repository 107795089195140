// Router - routes loaded into main content area
import Router from 'router/Router';
import { defaultRoute } from 'router/routes';
import { useLocation } from 'react-router-dom';

// components
import PageHelmet from 'components/seo/Helmet/PageHelmet/PageHelmet';

// theming
import styles from './Main.module.scss';

function Main() {
  const location = useLocation();
  const locationState = location.state as { id: string }; // custom id added to location state
  const sectionId = locationState ? locationState.id : defaultRoute;

  return (
    <main className={styles.Main}>
      <PageHelmet pageType={sectionId} />

      <section id={sectionId}>
        <Router />
      </section>
    </main>
  );
}

export default Main;
