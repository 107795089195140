import { createSlice } from '@reduxjs/toolkit';
import { PortfolioPieces } from 'types/portfolioTypes';

const initialState: PortfolioPieces = {
  loading: false,
  loaded: false,
  cards: [],
  employers: [],
};

// REDUCERS
const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setEmployers: (state, action) => {
      state.employers = action.payload;
    },
  },
});

// ACTION CREATORS
export const { setLoading, setLoaded, setCards, setEmployers } = portfolioSlice.actions;

export default portfolioSlice.reducer;
