// types
import { ContactItem as ContactItemType } from 'types/contactTypes';

// theming
import styles from './ContactItem.module.scss';

/**
 * One Contact item on the Contact landing page
 *
 * @param desc: string
 * @param link: string
 * @param img: string
 * @param alt: string
 *
 * @constructor
 */
function ContactItem({ desc, link, img, alt }: ContactItemType) {
  return (
    <li className={styles.ContactItem}>
      <div className={styles.icon}>
        <a href={link} target='_blank' rel='noopener noreferrer'>
          <img src={`images/contact/${img}.svg`} alt={alt} width='80px' height='80px' />
        </a>
      </div>
      <div className={styles.description}>
        <h4>{desc}</h4>
        <a href={link} target='_blank' rel='noopener noreferrer'>
          {link}
        </a>
      </div>
    </li>
  );
}

export default ContactItem;
