import React from 'react';

// router
import { useNavigate } from 'react-router-dom';

// internationalization
import { useTranslation } from 'react-i18next';

// theme
import logo from 'assets/svg/mike-trakan-logo.svg';
import styles from './Mascot.module.scss';

function Mascot() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate('/');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate('/');
    }
  };

  return (
    <div
      className={styles.mascot}
      role='button'
      aria-label={t('header.shortTitle')}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <img src={logo} alt='' />
    </div>
  );
}

export default Mascot;
