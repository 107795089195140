import React, { Suspense, useEffect } from 'react';

// Redux Toolkit
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { portfolioActions as actions } from 'store/actions/portfolioActions';

// internationalization
import { useTranslation } from 'react-i18next';

// constants
import APP from 'constants/app';

// components
import Loading from 'components/molecules/Loading/Loading';

// data
import getSuspendedCards from 'api/portfolioApi';

// theming
import styles from './Portfolio.module.scss';

let suspendedCardData: { readData: () => unknown };

/**
 * Portfolio Landing Page
 *
 * @constructor
 */
function Portfolio() {
  const { t } = useTranslation();

  // code splitting
  const SuspendedPortfolio = React.lazy(
    () => import('components/organisms/SuspendedPortfolio/SuspendedPortfolio'),
  );

  // redux
  const dispatch = useAppDispatch();
  const loaded = useAppSelector((state) => state.portfolio.loaded);

  // mount
  useEffect(() => {
    if (!loaded) {
      // pass to the suspended component to work its magic
      suspendedCardData = getSuspendedCards();
      // dispatch redux action to the saga
      dispatch({ type: actions.PORTFOLIO__FETCH });
    }
  }, [loaded, dispatch]);

  const { portfolio: PORTFOLIO_LOADER } = APP.routes;

  return (
    <div className={styles.PortfolioPage}>
      <div className={styles.sectionHead}>
        <h2>{t('greetings.main')}</h2>
        <p className='h5'>{t('greetings.sub')}</p>
      </div>

      <Suspense fallback={<Loading quantity={4} type={PORTFOLIO_LOADER} />}>
        <SuspendedPortfolio resources={suspendedCardData} />
      </Suspense>
    </div>
  );
}

export default Portfolio;
