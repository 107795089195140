import { Route, Routes } from 'react-router-dom';
import AppRoutes from 'router/routes';
import PageNotFound404 from 'components/pages/404/404';

function Router() {
  const routingComponents = AppRoutes.map(({ id, path, component }) => {
    return <Route key={id} path={path} element={component} />;
  });

  return (
    <Routes>
      {routingComponents}
      <Route path='*' element={<PageNotFound404 />} />
    </Routes>
  );
}

export default Router;
