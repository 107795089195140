// Route Landing Pages
import Portfolio from 'components/pages/Portfolio/Portfolio';
import Resume from 'components/pages/Resume/Resume';
import Contact from 'components/pages/Contact/Contact';

// constants
import APP from 'constants/app';

/**
 * icon: name from assets/fonts/icomoon/selection.json (key: tags)
 */
const Routes = [
  {
    id: APP.routes.portfolio,
    path: '/',
    icon: 'briefcase-solid',
    page: 'Portfolio',
    component: <Portfolio />,
  },
  {
    id: APP.routes.resume,
    path: '/resume',
    icon: 'envelope-solid',
    page: 'Resume',
    component: <Resume />,
  },
  {
    id: APP.routes.contact,
    path: '/contact',
    icon: 'file-lines-solid',
    page: 'Contact',
    component: <Contact />,
  },
];

export const defaultRoute = APP.routes.portfolio;
export default Routes;
