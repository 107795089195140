import { call, put, takeLatest } from 'redux-saga/effects';

// api calls
import { getResumeRedux } from 'api/resumeApi';

// redux
import {
  setLoading,
  setLoaded,
  setExperiences,
  setTechs,
  setSchools,
  setCerts,
} from '../reducers/resumeSlice';

import { resumeActions as actions } from '../actions/resumeActions';

export function* fetchResume() {
  try {
    yield put(setLoading(true));

    // @ts-ignore todo - add generator types
    const result = yield call(getResumeRedux);

    if (
      result &&
      result.length &&
      result[0].experience &&
      result[0].techs &&
      result[0].education &&
      result[0].certs
    ) {
      const { experience, techs, education, certs } = result[0];

      // set resume experiences
      yield put(setExperiences(experience));

      // set techs
      yield put(setTechs(techs));

      // set techs
      yield put(setSchools(education));

      // set techs
      yield put(setCerts(certs));

      yield put(setLoaded(true));
      yield put(setLoading(false));
    } else {
      throw new Error('resume api failed to load data');
    }
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e);
    yield put(setLoading(false));
  }
}

function* resumeSaga() {
  yield takeLatest(actions.RESUME__FETCH, fetchResume);
}

// eslint-disable-next-line import/prefer-default-export
export const sagas = [resumeSaga()];
