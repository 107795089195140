export const wrapCallInPromise = (promise: Promise<unknown>) => {
  const STATUS = {
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
  };

  const { PENDING, SUCCESS, ERROR } = STATUS;

  let status = PENDING; // status of execution
  let result: unknown; // data to be returned

  const suspender = promise
    .then((resp) => {
      status = SUCCESS;
      result = resp;
    })
    .catch((err) => {
      status = ERROR;
      result = err;
    });

  return {
    /* eslint-disable consistent-return */
    readData() {
      if (status === PENDING) {
        throw suspender; // suspend the component by throwing the suspender
      } else if (status === SUCCESS) {
        return result; // return the data successfully fetched
      } else if (status === ERROR) {
        throw result; // throw the error and suspend the component
      }
    },
  };
};

/**
 * REACT 18 SUSPENSE DEMO
 */

// todo - tweak in production or remove if not wanting to demo suspense
const simulatedDelay = 300;

export const serveFlatFile = (flatfileJSON: {}) => {
  const dataPromise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(flatfileJSON);
    }, simulatedDelay);
  });

  return wrapCallInPromise(dataPromise);
};
