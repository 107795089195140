// internationalization
import { useTranslation } from 'react-i18next';

// components
import NavItem from 'components/molecules/NavItem/NavItem';

// constants
import Routes from 'router/routes';

// theming
import styles from './Navbar.module.scss';

function Navbar({ curRoute }: { curRoute: any }) {
  const { t } = useTranslation();

  const NavItems = Routes.map((route) => {
    const { id, path, icon } = route;

    return (
      <li key={id} className={styles.NavBtn}>
        <NavItem
          id={id}
          text={t(`nav.${id}`)}
          icon={icon}
          route={path}
          active={curRoute === path}
        />
      </li>
    );
  });

  return (
    <nav>
      <div className={styles.navbarMenu}>
        <div className={styles.router}>
          <ul>{NavItems}</ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
