import APP from 'constants/app';

const SEO = {
  type: 'website',
  siteName: 'Mike Trakan – Front-End Developer',
  siteUrl: 'https://miketrakan.com',
  siteDesc:
    'Chicago-based front-end web app developer, specializing in JavaScript apps. Travels with lots of UX and design love.', // 300 chars max
  robots: 'index, follow',

  charSet: 'utf-8',
  themeColor: '475c73',
  viewport: 'width=device-width, initial-scale=1',

  imgHt: '640',
  imgWd: '1280',

  twitterHandle: '@trakanmusic',
  twitterCardType: 'summary_large_image',

  [APP.routes.portfolio]: {
    imgUrl: 'public/images/social/preview-1280x640.png',
    pageTitle: 'Mike Trakan',
    pageDesc: "Things I've worked on recently that make me proud.",
  },
  [APP.routes.resume]: {
    imgUrl: 'public/images/social/preview-1280x640.png',
    pageTitle: 'Mike Trakan – Resume',
    pageDesc: 'Over 20 years experience with JavaScript and a variety of other technologies.',
  },
  [APP.routes.contact]: {
    imgUrl: 'public/images/social/preview-1280x640.png',
    pageTitle: 'Mike Trakan – Contact',
    pageDesc: "Drop me a line. Let's work together!",
  },
};

export default SEO;
