// internationalization
import { useTranslation } from 'react-i18next';

// components
import ContactItem from 'components/molecules/ContactItem/ContactItem';

// constants
import APP from 'constants/app';

// theming
import styles from './Contact.module.scss';

/**
 * Contact Landing Page
 * - loads synchronously using i18n variables
 *
 * @constructor
 */
function Contact() {
  const { t } = useTranslation();
  const { email, linkedIn, gitHub } = APP.contact;

  return (
    <div className={styles.ContactPage}>
      <div className={styles.sectionHead}>
        <h2>{t('contact.greeting')}</h2>
        <p className='h5'>{t('contact.cta')}</p>
      </div>

      {/* CONTACT */}
      <article>
        <h3>{t('contact.contact')}</h3>
        <ul>
          {/* Email */}
          <ContactItem
            desc={t('contact.email.desc')}
            link={t('contact.email.address')}
            img={email}
            alt={t('contact.email.alt')}
          />

          {/* LinkedIn */}
          <ContactItem
            desc={t('contact.linkedin.desc')}
            link={t('contact.linkedin.address')}
            img={linkedIn}
            alt={t('contact.linkedin.alt')}
          />
        </ul>
      </article>

      {/* CODE SAMPLES */}
      <article>
        <h3>{t('contact.social')}</h3>
        <ul>
          {/* GitHub */}
          <ContactItem
            desc={t('contact.github.desc')}
            link={t('contact.github.address')}
            img={gitHub}
            alt={t('contact.github.alt')}
          />
        </ul>
      </article>
    </div>
  );
}

export default Contact;
