import { v4 as uuidv4 } from 'uuid';

// constants
import APP from 'constants/app';

// loader components
import ContentLoader from 'react-content-loader';
import { PortfolioLoader, ResumeLoader, DefaultLoader } from './Loaders';

// theming
import styles from './Loading.module.scss';

type Props = {
  type: string;
  quantity?: number;
};

function Loading({ quantity = 1, type }: Props) {
  const { portfolio, resume } = APP.routes;
  const loaders = [];

  let Loader = ContentLoader;
  switch (type) {
    case portfolio:
      Loader = PortfolioLoader;
      break;
    case resume:
      Loader = ResumeLoader;
      break;
    default:
      Loader = DefaultLoader;
  }

  for (let i = 0; i < quantity; i += 1) {
    loaders.push(<Loader key={`skeleton-${uuidv4()}`} />);
  }

  return (
    <div
      data-testid='skeleton-loader'
      className={`${styles.skeletonWrap} ${styles[type]}`}
      tabIndex={0}
      role='progressbar'
      aria-busy='true'
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuetext={`loading ${type}`}
      aria-label={`loading ${type}`}
    >
      <ul>{loaders}</ul>
    </div>
  );
}

Loading.defaultProps = {
  quantity: 1,
} as Partial<Props>;

export default Loading;
