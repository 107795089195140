import React, { Suspense, useEffect } from 'react';

// Redux Toolkit
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { resumeActions as actions } from 'store/actions/resumeActions';

// internationalization
import { useTranslation } from 'react-i18next';

// constants
import APP from 'constants/app';

// components
import Loading from 'components/molecules/Loading/Loading';

// data
import getSuspendedResume from 'api/resumeApi';

// theming
import pdfImg from 'assets/svg/pdf.svg';
import styles from './Resume.module.scss';

let suspendedResumeData: { readData: () => unknown };

/**
 * Resume Landing Page
 * @constructor
 */
function Resume() {
  const { t } = useTranslation();

  // code splitting
  const SuspendedResume = React.lazy(
    () => import('components/organisms/SuspendedResume/SuspendedResume'),
  );

  // redux
  const dispatch = useAppDispatch();
  const loaded = useAppSelector((state) => state.resume.loaded);

  // mount
  useEffect(() => {
    if (!loaded) {
      // pass to the suspended component to work its magic
      suspendedResumeData = getSuspendedResume();
      // dispatch redux action to the saga
      dispatch({ type: actions.RESUME__FETCH });
    }
  }, [loaded, dispatch]);

  const { resume: RESUME_LOADER } = APP.routes;

  return (
    <div className={styles.ResumePage}>
      <div className={styles.sectionHead}>
        <a href='/docs/TRAKAN-resume.pdf' target='_blank' rel='noopener noreferrer'>
          <span>{t('resume.pdf')}</span>
          <span>
            <img src={pdfImg} alt={t('resume.pdfAlt')} width='60px' height='60px' />
          </span>
        </a>
      </div>

      <Suspense fallback={<Loading quantity={4} type={RESUME_LOADER} />}>
        <SuspendedResume resources={suspendedResumeData} />
      </Suspense>
    </div>
  );
}

export default Resume;
