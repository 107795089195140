import IcoMoon, { IconProps } from 'react-icomoon';
import iconSet from 'assets/fonts/icomoon/selection.json';

/**
 * Icon
 * @group  Atom
 *
 * @param {IconProps} props - react-icomoon: https://github.com/aykutkardas/react-icomoon
 * @constructor
 */
/* eslint-disable react/jsx-props-no-spreading */
function Icon(props: IconProps) {
  return <IcoMoon iconSet={iconSet} {...props} />;
}

export default Icon;
