// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_EN = {
  header: {
    title: 'Mike Trakan',
    shortTitle: 'Trakan',
  },
  footer: {
    desc: 'Portfolio built 2022-23 by Mike Trakan with React 18 and TypeScript 4.7',
  },
  nav: {
    portfolio: 'portfolio',
    resume: 'resume',
    contact: 'contact',
  },
  greetings: {
    main: 'Senior Front-End Engineer in Chicago',
    sub: 'JavaScript apps (with a little backend muscle, much UX/design love... & a bottle of hotsauce just in case)',
  },
  portfolio: {
    headline: "Things I've worked on recently that make me proud",
    past: "Places Where I've Built Stuff In The Past",
    description: 'What it is:',
    myRole: 'What I brought to the app:',
    techsUsed: 'Stuff I used:',
    myMemory: 'What I remember:',
  },
  resume: {
    pdf: 'pdf download:',
    pdfAlt: `Download Resume pdf`,
    headers: {
      experience: 'Past Experience',
      techs: 'Core Technology, Tools & Software Experience',
      education: 'Education',
      certs: 'Training & Certifications',
    },
  },
  contact: {
    greeting: "My friends call me 'Trakan'",
    cta: '(Pronounced "Tray-kin", sorta like "Bacon with a T")',
    contact: "Drop me a line. Let's work together!",
    social: 'Some personal projects',
    email: {
      desc: 'Shoot me an email',
      address: 'mailto:mike@trakan.com',
      alt: 'Email',
    },
    linkedin: {
      desc: 'Connect with me on LinkedIn',
      address: 'https://www.linkedin.com/in/trakan/',
      alt: 'LinkedIn',
    },
    github: {
      desc: 'Code samples (ask about my private repos)',
      address: 'https://github.com/Trakan',
      alt: 'GitHub',
    },
  },
  404: {
    header: 'Whoops. ',
    subhead: "Not sure if this is the page you're looking for.",
    subhead2: "If so, I don't have it on me. Sorry.",
    cta: "While you're here, want to check out my",
    or: 'or',
    instead: 'instead',
    pages: {
      portfolio: 'work history',
      resume: 'resume',
      contact: 'contact page',
    },
  },
  errors: {
    fallback: {
      header: 'Oh no! Something went wrong loading this page!',
      error: 'error',
      btnText: 'Forget this ever happened and reset the site',
      btnLabel: 'click to try and reload',
      apology: 'Sorry for this inconvenience!',
    },
  },
};
